import React, { useEffect, useState } from "react";
import LayoutDefault from '../layout/Layout';
import "animate.css"
import PaperAndPencil from '../../assets/images/piece-of-paper-and-pencil.png';
import Cog from '../../assets/images/clutch-disc.png';
import Dirt from '../../assets/images/Dirt.jpg';
import Cost from '../../assets/images/CostTracking.png';


document.title ="The K.A. Group Internal Support"; 

// SANITIZE HTML DATA
// var sanitizer = DOMPurify.sanitize;

const Quality = ({
  
}) => {

    const [isActive, setActive] = useState(false);


    const toggleClass = () => {
        setActive(!isActive);
    };

    const SmartText = ({ text, length = 130 }) => {
        const [showLess, setShowLess] = React.useState(true);
      
        if (text.length < length) {
          return <p>{text}</p>;
        }
      
        return (
          <div>
            <p
              dangerouslySetInnerHTML={{
                __html: showLess ? `${text.slice(0, length)}...` : text,
              }}
            ></p>
            <a
              style={{ color: "#fff", cursor: "pointer" }}
              onClick={() => setShowLess(!showLess)}
            >
              &nbsp;View {showLess ? "More" : "Less"}
            </a>
          </div>
        );
    };

    return (
        <>
            <LayoutDefault className="width-1200">
                <img  className='dirt-img' src={Dirt} alt="Dirt" />

                <a style={{ fontSize: '20px', margin: '30px 0', display: 'block' }} href="/">&lt; Back</a>

                <h1 style={{ marginBottom: '23px' }} className="animate__animated animate__fadeInLeft animate__fast">Quality Control</h1>
                <p style={{ marginBottom: '78.5px' }} className="animate__animated animate__fadeInLeft animate__fast">Whether it's production or shipping, we pride ourselves in having top-notch quality. Notify us so we can prevent future issues.</p>
                
                <div className="block-row row">
                    <div className="animate__animated animate__fadeInUp animate__delay-1s animate__fast block column">
                        <img src={PaperAndPencil} alt="Paper and Pencil" />
                        <h2>General Quality Issue</h2>
                        <SmartText text="Help track recurring issues and stop one-offs by submitting all quality issues. Big or small, it must be logged." />
                        <a className="custom-btn" href="https://forms.clickup.com/f/dhthx-17127/ZOBCBOBJ1L6IK7VA43?Destination%20List=Product%20Support&Product%20Support%20Category=Quality%20Issue" target="_blank" rel="noopener noreferrer">Submit Request</a>
                    </div>

                    <div className="animate__animated animate__fadeInUp animate__delay-1s animate__fast block column">
                        <img src={Cog} alt="Cog" />
                        <h2>Parts Sales/Shipping Quality Issue</h2>
                        <SmartText text="Submit a ticket to start the investigation process and help prevent future delivery issues to our customers." />
                        <a className="custom-btn" href="https://forms.clickup.com/14214717/f/dhthx-20307/5CI6CKJLGP8TOEJN4D?Destination%20List=PSQuality&QSR%20Category=PS/Shipping%20Quality" target="_blank" rel="noopener noreferrer">Submit Request</a>                        
                    </div>

                    <div className="animate__animated animate__fadeInUp animate__delay-1s animate__fast block column">
                        <img src={Cost} alt="Cost" />
                        <h2>Quality Control Cost Tracking</h2>
                        <SmartText text="Forms for tracking Quality Issue Costs." />
                        <a className="custom-btn" href="/qccosttracking" rel="noopener noreferrer">View More</a>                        
                    </div>
                </div>

            </LayoutDefault>
        </>
    );
}

export default Quality;