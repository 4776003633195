import React, { useEffect, useState } from "react";
import LayoutDefault from '../layout/Layout';
import "animate.css"
import Dirt from '../../assets/images/Dirt.jpg';
import Coaching from '../../assets/images/Coaching.png';
import Safety from '../../assets/images/Safety.png';
import PlaneCar from '../../assets/images/PlaneCar.png';


document.title ="The K.A. Group Internal Support"; 

// SANITIZE HTML DATA
// var sanitizer = DOMPurify.sanitize;

const HumanResources = ({
  
}) => {

    const [isActive, setActive] = useState(false);


    const toggleClass = () => {
        setActive(!isActive);
    };

    const SmartText = ({ text, length = 150 }) => {
        const [showLess, setShowLess] = React.useState(true);
      
        if (text.length < length) {
          return <p>{text}</p>;
        }
      
        return (
          <div>
            <p
              dangerouslySetInnerHTML={{
                __html: showLess ? `${text.slice(0, length)}...` : text,
              }}
            ></p>
            <a
              style={{ color: "#fff", cursor: "pointer" }}
              onClick={() => setShowLess(!showLess)}
            >
              &nbsp;View {showLess ? "More" : "Less"}
            </a>
          </div>
        );
    };

    return (
        <>
            <LayoutDefault className="width-1200">
                <img  className='dirt-img' src={Dirt} alt="Dirt" />

                <a style={{ fontSize: '20px', margin: '30px 0', display: 'block' }} href="/">&lt; Back</a>

                <h1 style={{ marginBottom: '23px' }} className="animate__animated animate__fadeInLeft animate__fast">Human Resources</h1>
                <p style={{ marginBottom: '78.5px' }} className="animate__animated animate__fadeInLeft animate__fast">HR/Safety and all other things.</p>
                
                <div className="block-row row">
                  {/* <div className="animate__animated animate__fadeInUp animate__delay-1s animate__fast block column">
                      <img src={PlaneCar} alt="Time Off Request" />
                      <h2>Time Off Request</h2>
                      <SmartText text="Form to submit time off requests for Salaried Employees" />
                      <a style={{ marginTop: '54px' }} className="custom-btn" href="https://forms.office.com/Pages/ResponsePage.aspx?id=JxF44ckTQUK_Ecq91KnG_dReXjpVN7xHoKgvyWCUsIFUMzZVOU81QjRISEdSOUxTN1ZWQk1USTdMUi4u" target="_blank" rel="noopener noreferrer">Submit Request</a>                        
                  </div> */}

                  <div className="animate__animated animate__fadeInUp animate__delay-1s animate__fast block column">
                        <img src={Safety} alt="Paper and Pencil" />
                        <h2>Safety Violations</h2>
                        <SmartText text="Help track safety violations and write ups. Big or small, it must be logged." />
                        <a className="custom-btn" href="https://forms.office.com/Pages/ResponsePage.aspx?id=JxF44ckTQUK_Ecq91KnG_Y0bebsIpcdNrAzNiv1Zt2FUNVA5Nk1FVDI2SUM4QTY4VkFOMDE3QlhEVCQlQCN0PWcu" target="_blank" rel="noopener noreferrer">Submit Record</a>
                    </div>

                    <div className="animate__animated animate__fadeInUp animate__delay-1s animate__fast block column">
                        <img src={Coaching} alt="Employee Coaching" />
                        <h2>Employee Coaching</h2>
                        <SmartText text="Form to record various Employee Coaching actions." />
                        <a className="custom-btn" href="https://forms.office.com/r/QzWdwRuywq?origin=lprLink" target="_blank" rel="noopener noreferrer">Submit Record</a>
                    </div>

                  {/* <div className="animate__animated animate__fadeInUp animate__delay-1s animate__fast block column">
                      <img src={NewUser} alt="New ClickUp User" />
                      <h2>New ClickUp User</h2>
                      <SmartText text="Request creations for new ClickUp users and we'll provide the training. For mulitple users include all email addresses in a single submission." />
                      <a className="custom-btn" href="https://forms.clickup.com/f/dhthx-18167/WDZANMUS9ZYHYNIDRH?Destination%20List=New%20ClickUp%20User" target="_blank" rel="noopener noreferrer">Submit Request</a>
                  </div> */}

                  {/* <div className="animate__animated animate__fadeInUp animate__delay-1s animate__fast block column">
                      <img src={Ticket} alt="Reporting Ticket" />
                      <h2>New Report Request</h2>
                      <SmartText text="Enter a report into the funnel and the reporting team will assess and determine the best course of action." />
                      <a className="custom-btn" href="https://forms.clickup.com/14214717/f/dhthx-31211/O93T05N28A0D5K528O?Destination%20List=IT&IT%20Category=Reporting" target="_blank" rel="noopener noreferrer">Submit Request</a>
                  </div> */}

                </div>
            </LayoutDefault>
        </>
    );
}

export default HumanResources;