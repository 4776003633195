import React, { useEffect, useState } from "react";
import LayoutDefault from '../layout/Layout';
import "animate.css"
import PaperAndPencil from '../../assets/images/piece-of-paper-and-pencil.png';
import Cog from '../../assets/images/clutch-disc.png';
import Dirt from '../../assets/images/Dirt.jpg';
import Box from '../../assets/images/box.png';
import Globe from '../../assets/images/globe.png';
import Transfer from '../../assets/images/transfer.png';
import BackorderParts from '../../assets/images/maintenance.svg';
import FastDelivery from '../../assets/images/fast-delivery.svg';
import Requests from '../../assets/images/request-(1).svg'
import Truck from '../../assets/images/delivery-truck.png';

document.title ="The K.A. Group Internal Support"; 

// SANITIZE HTML DATA
// var sanitizer = DOMPurify.sanitize;

const Inventory = ({
  
}) => {

    const [isActive, setActive] = useState(false);


    const toggleClass = () => {
        setActive(!isActive);
    };

    const SmartText = ({ text, length = 130 }) => {
        const [showLess, setShowLess] = React.useState(true);
      
        if (text.length < length) {
          return <p>{text}</p>;
        }
      
        return (
          <div>
            <p
              dangerouslySetInnerHTML={{
                __html: showLess ? `${text.slice(0, length)}...` : text,
              }}
            ></p>
            <a
              style={{ color: "#fff", cursor: "pointer" }}
              onClick={() => setShowLess(!showLess)}
            >
              &nbsp;View {showLess ? "More" : "Less"}
            </a>
          </div>
        );
    };

    return (
        <>
            <LayoutDefault className="width-1200">
                <img  className='dirt-img' src={Dirt} alt="Dirt" />

                <a style={{ fontSize: '20px', margin: '30px 0', display: 'block' }} href="/">&lt; Back</a>

                <h1 style={{ marginBottom: '23px' }} className="animate__animated animate__fadeInLeft animate__fast">Purchasing & Inventory Requests</h1>
                <p style={{ marginBottom: '78.5px' }} className="animate__animated animate__fadeInLeft animate__fast">All Purchasing and Inventory related tickets.</p>
                
                <div className="block-row row">
                    <div className="animate__animated animate__fadeInUp animate__delay-1s animate__fast block column">
                        <img src={BackorderParts} alt="Inventory" />
                        <h2>Inventory Shortages</h2>
                        <SmartText text="Form to track inventory shortages, either from suppliers, subcontractors or internal production." />
                        <a className="custom-btn" href="https://forms.clickup.com/f/dhthx-17107/RH6WU0NKZZ1CT1IR6M?Destination%20List=InventoryPurchasing" target="_blank" rel="noopener noreferrer">Submit Request</a>
                    </div>
                    <div className="animate__animated animate__fadeInUp animate__delay-1s animate__fast block column">
                        <img src={Transfer} alt="Transfer" />
                        <h2>Inventory Transfer</h2>
                        <SmartText text="Moving inventory to any Company, Department, or Yard within KA Group." />
                        <a className="custom-btn" href="https://forms.clickup.com/14214717/f/dhthx-20682/UCYSSQX3NENLVCHIR5?Destination%20List=InterCo" target="_blank" rel="noopener noreferrer">Submit Request</a>                        
                    </div>
                    <div className="animate__animated animate__fadeInUp animate__delay-1s animate__fast block column">
                        <img src={FastDelivery} alt="FastDelivery" />
                        <h2>Purchase Request</h2>
                        <SmartText text="Material or Item Requisition Form" />
                        <a className="custom-btn" href="https://forms.clickup.com/14214717/f/dhthx-31631/V00WV7KLL4FTNRP69S?Destination%20List=PurchaseRequest" target="_blank" rel="noopener noreferrer">Submit Request</a>                        
                    </div>

                </div>
                
                  <div className="block-row row">
                      <div className="animate__animated animate__fadeInUp animate__delay-1s animate__fast block column">
                          <img src={Truck} alt="Globe" />
                          <h2>Mis-Ship</h2>
                          <SmartText text="Submit a ticket to start the investigation process and help prevent future receiving issues." />
                          <a className="custom-btn" href="https://forms.clickup.com/14214717/f/dhthx-37171/HZK4V6LDEJRLYC3E5K" target="_blank" rel="noopener noreferrer">Submit Request</a>
                      </div>
                      <div className="column"></div>
                      <div className="column"></div>
                  </div>
            </LayoutDefault>
        </>
    );
}

export default Inventory;